import { navigate } from 'gatsby';
import React, { useEffect } from 'react';

import { isBrowser } from '@/common/utils';
import MetaTags from '@/components/meta-tags';
import RingLoader from '@/components/rings/rings';

import '@/assets/scss/app.scss';

function IndexPage() {
  useEffect(() => {
    if (isBrowser()) {
      try {
        const pathname = window.location.pathname;
        const requestedRoutePart2 = pathname
          .replace('/', '')
          .split('/')[1]
          .replace('/')
          .split('/')[0];
        const redirectUrl = `/seasons/?r=${encodeURIComponent(
          requestedRoutePart2,
        )}`;
        navigate(redirectUrl);
      } catch (error) {
        // Handle the error (e.g., log it, show a message, etc.)
        console.error('Error during route update:', error);
      }
    }
  }, []);

  return <RingLoader />;
}

export function Head() {
  return (
    <>
      <MetaTags />
    </>
  );
}

export default IndexPage;
